import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setPhotosMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { addPhotos, updatePhotos } from "services/photosService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
import { uploadFileService, uploadImageService } from "services/fileUploadService";
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const PhotosForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:0,image:'',created_at:'',updated_at:''};
    const initialValue = action === 'edit' ? row : iValue;

    const [message, setMessage] = useState('')
    const [imageFieldValue, setImageFieldValue] = useState('');

    const formikImage = useFormik({
        initialValues: { image: [] },
        onSubmit: async (values) => {
            var formData = new FormData();
            formData.append("image", values.image[0]);
            uploadImageService(formData).then((res: any) => {
                if (res && res.data && res.data.success) {
                    setMessage('Image Successfully Uploaded : ' + res.data.file);

                    setImageFieldValue(res.data.file);
                    formik.setFieldValue('image',res.data.file)

                    formikImage.resetForm();
                }else{
                    setMessage('Failed to upload image');
                    formikFile.resetForm();
                }
            })
        },
        validationSchema: yup.object({
            image: yup.array().min(1, "Select at least 1 image")
        }),
    });

    const formikFile = useFormik({
        initialValues: { fileData: [] },
        onSubmit: async (values) => {
            var formData = new FormData();
            formData.append("file", values.fileData[0]);
            uploadFileService(formData).then((res: any) => {
                if (res && res.data && res.data.success) {
                    setMessage('File Successfully Uploaded : ' + res.data.file);
                    formikFile.resetForm();
                }else{
                    setMessage('Failed to upload file');
                    formikFile.resetForm();
                }
            })
        },
        validationSchema: yup.object({
            fileData: yup.array().min(1, "Select at least 1 file")
        }),
    });
    
    
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updatePhotos(values);
                if (response && response.data && response.data.code===1) {
                    dispatch(setPhotosMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setPhotosMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addPhotos(values);
                if (response && response.data && response.data.code===1) {
                    dispatch(setPhotosMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setPhotosMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           image: yup.string().required('image is required'),
created_at: yup.date().nullable(),
updated_at: yup.date().nullable(),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action} Photos
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Photos</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
            <Form onSubmit={formikImage.handleSubmit}>
            {message ? <Col md={12}><Alert variant="info"><img src={"https://college.sindhusagaracademy.co.in/SindhuAPI/public/uploads/"+imageFieldValue} alt="Image" width={70} /></Alert></Col> : null}
                                        <Form.Group controlId="formFile" className="mb-3">
                                            <Form.Label>Upload png | jpg | jpeg files</Form.Label>
                                            <Form.Control type="file" 
                                                onChange={(event: React.ChangeEvent) => {
                                                    const imgFile = event.target as HTMLInputElement;
                                                    if (imgFile.files && imgFile.files[0]) {
                                                        formikImage.setFieldValue("image", Array.from(imgFile.files));
                                                    }
                                                }}
                                                onBlur={formikImage.handleBlur}
                                                isInvalid={!!formikImage.touched.image && !!formikImage.errors.image}
                                                isValid={!!formikImage.touched.image && !formikImage.errors.image}
                                            />
                                            {
                                                formikImage.errors.image && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {formikImage.errors.image}
                                                    </Form.Control.Feedback>
                                                )}
                                        </Form.Group>
                                        <Form.Group>
                                            <Button type="submit" className="float-right" variant="primary">Upload</Button>
                                        </Form.Group>
                                    </Form>

                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group style={{display:'none'}}>
<label className="form -control-label">image</label>
<Form.Control type="text" name="image" className="form-control" value={ imageFieldValue}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.image && !!formik.errors.image}
isValid ={!!formik.touched.image && !formik.errors.image}
></Form.Control>
{
    formik.errors.image && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.image}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group style={{display:'none'}}>
<label className="form -control-label">created_at</label>
<Form.Control type="text" name="created_at" className="form-control" value={formik.values.created_at}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.created_at && !!formik.errors.created_at}
isValid ={!!formik.touched.created_at && !formik.errors.created_at}
></Form.Control>
{
    formik.errors.created_at && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.created_at}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group style={{display:'none'}}>
<label className="form -control-label">updated_at</label>
<Form.Control type="text" name="updated_at" className="form-control" value={formik.values.updated_at}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.updated_at && !!formik.errors.updated_at}
isValid ={!!formik.touched.updated_at && !formik.errors.updated_at}
></Form.Control>
{
    formik.errors.updated_at && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.updated_at}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

